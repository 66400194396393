import React, { useMemo } from "react";
import block from "bem-cn";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "features/Auth";

import menuItems from "./data";
import MenuItem from "./MenuItem";

import "./Menu.scss";

const Menu = () => {
  const b = block("menu");
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const { role, isMenuWindowOpen } = useSelector(state => state.auth);

  // const onCloseWindowBlock = ()=> {
  //   dispatch(actions.closeMenuWindow())
  // }

  // const WindowBlock = (
  //   <div className={b('window-block')}>
  //     <div className={b('window-block-inner')}>
  //       <span>الرجاء الاتصال بهذا الرقم لاستعادة حسابك في الموقع</span><br/>
  //       <span>56738875</span>
  //     </div>
  //     <button onClick={onCloseWindowBlock}>{locale.close}</button>
  //   </div>
  // )

  const itemList = useMemo(
    () =>
      menuItems.map(temp => (
        <MenuItem locale={locale} role={role} item={temp} key={temp.textId} />
      )),
    [locale, role]
  );

  return (
    <section className={b()}>
      {itemList}
      {/* {isMenuWindowOpen && WindowBlock} */}
    </section>
  );
};

export default Menu;
